<template>
    <div>
        <div class="box">
            <div class="box-title">用户手册</div>
            <div class="box-synopsis-wrap">
                <div class="box-synopsis mouseStyle" v-for="item in 8" :key="item">
                    <router-link :to="'/DOC/details/' + nav[item-1].name">
                        <img :src="synopsis_img[item-1]" alt="">
                    </router-link>
                    <router-link :to="'/DOC/details/' + nav[item-1].name">
                        <div class="box-synopsis-right">
                            <p class="subtitle">{{nav[item-1].name}}</p>
                            <p>{{nav[item-1].first}}</p>
                            <p>{{nav[item-1].second}}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="box-title">订单流程</div>
            <div class="select">
                <el-button size='small' :type="is ? 'primary' : ''" @click="is = !is">先货后款</el-button>
                <el-button size='small' :type="is ? '' : 'primary'" @click="is = !is">先款后货</el-button>
            </div>

            <div class="process">
                <div class="process-img" v-for="(item,index) in image" :key="is ? index + 'a' : index + 'b'">
                    <img :src="item" alt="">
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        computed: {
            image() {
                if (this.is) {
                    return this.process_img_first
                } else {
                    return this.process_img_second
                }
            }
        },

        components: {

        },
        data() {
            return {
                synopsis_img: [
                    require('@/assets/Doc/synopsis_img/synopsis_img_1.png'),
                    require('@/assets/Doc/synopsis_img/synopsis_img_2.png'),
                    require('@/assets/Doc/synopsis_img/synopsis_img_3.png'),
                    require('@/assets/Doc/synopsis_img/synopsis_img_4.png'),
                    require('@/assets/Doc/synopsis_img/synopsis_img_5.png'),
                    require('@/assets/Doc/synopsis_img/synopsis_img_6.png'),
                    require('@/assets/Doc/synopsis_img/synopsis_img_7.png'),
                    require('@/assets/Doc/synopsis_img/synopsis_img_8.png'),
                ],
                process_img_first: [
                    require('@/assets/Doc/process_img_first/process_img_1.png'),
                    require('@/assets/Doc/process_img_first/process_img_2.png'),
                    require('@/assets/Doc/process_img_first/process_img_3.png'),
                    require('@/assets/Doc/process_img_first/process_img_4.png'),
                    require('@/assets/Doc/process_img_first/process_img_5.png'),
                    require('@/assets/Doc/process_img_first/process_img_6.png'),
                    require('@/assets/Doc/process_img_first/process_img_7.png'),
                    require('@/assets/Doc/process_img_first/process_img_8.png'),
                ],
                process_img_second: [
                    require('@/assets/Doc/process_img_second/process_img_1.png'),
                    require('@/assets/Doc/process_img_second/process_img_2.png'),
                    require('@/assets/Doc/process_img_second/process_img_3.png'),
                    require('@/assets/Doc/process_img_second/process_img_4.png'),
                    require('@/assets/Doc/process_img_second/process_img_5.png'),
                    require('@/assets/Doc/process_img_second/process_img_6.png'),
                ],
                nav: [
                    {
                        name: '注册流程',
                        first: '注册信息填写',
                        second: '文件上传指引'
                    },
                    {
                        name: '企业信息认证',
                        first: '企业信息、法人等',
                        second: '信息上传认证'
                    },
                    {
                        name: '账号管理',
                        first: '子账号信息',
                        second: '权限管理'
                    },
                    {
                        name: '供应商入驻',
                        first: '企业入驻供应商',
                        second: '申请审核'
                    },
                    {
                        name: '发布竞标',
                        first: '竞标信息发布操作',
                        second: '步骤指引'
                    },
                    {
                        name: '发布竞卖',
                        first: '竞买信息发布',
                        second: '操作步骤指引'
                    },
                    {
                        name: '发布竞买',
                        first: '竞买信息发布',
                        second: '操作步骤指引'
                    },
                    {
                        name: '订单流程',
                        first: '订单流程操作',
                        second: '步骤指引'
                    }
                ],
                is: true
            }
        },
        methods: {

        }

    }
</script>

<style lang="less" scoped>
    .box {
        margin: 54px 0 40px;
        background: #Fff;
        padding: 34px;
        position: relative;

        .box-title {
            font-size: 22px;
            color: #000;

            &::before {
                content: '';
                display: inline-block;
                margin-right: 8px;
                width: 4px;
                height: 14px;
                border-radius: 4px;
                background: rgba(3, 160, 252);
            }
        }

        // 快捷服务
        .box-synopsis-wrap {
            display: flex;
            flex-wrap: wrap;
            padding: 34px 0 0;

            .box-synopsis {
                display: flex;
                width: 190px;
                height: 74px;
                margin-right: 124px;
                margin-bottom: 43px;

                // 企业信息认证 过长
                &:nth-child(2),&:nth-child(3),&:nth-child(5) {
                    position: relative;

                    .box-synopsis-right {
                        position: absolute;
                        left: 88px;
                        top: 0;

                        .subtitle {
                            width: 120px;
                        }
                    }
                }



                &:nth-child(4),
                &:nth-child(8) {
                    margin-right: 0;
                }

                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    margin-bottom: 20px;
                }

                img {
                    width: 74px;
                    height: 74px;
                    margin-right: 14px;
                }

                .subtitle {
                    font-size: 20px;
                    color: #333;
                }

                p {
                    font-size: 14px;
                    color: #999;
                    margin-top: 4px;
                }
            }
        }
    }

    // 按钮
    .select {
        margin: 34px 0;
    }

    // 流程
    .process {
        display: flex;
        flex-wrap: wrap;

        .process-img {
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, .08);
            width: 257.5px;
            height: 277px;
            margin-bottom: 20px;
            margin-right: 34px;

            &:nth-child(4),
            &:nth-child(8) {
                margin-right: 0;
            }

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
                margin-bottom: 0px;
            }

            img {
                width: 100%;
                height: 100%
            }
        }
    }
</style>
