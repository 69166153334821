var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "box-title" }, [_vm._v("用户手册")]),
      _c(
        "div",
        { staticClass: "box-synopsis-wrap" },
        _vm._l(8, function (item) {
          return _c(
            "div",
            { key: item, staticClass: "box-synopsis mouseStyle" },
            [
              _c(
                "router-link",
                { attrs: { to: "/DOC/details/" + _vm.nav[item - 1].name } },
                [
                  _c("img", {
                    attrs: { src: _vm.synopsis_img[item - 1], alt: "" },
                  }),
                ]
              ),
              _c(
                "router-link",
                { attrs: { to: "/DOC/details/" + _vm.nav[item - 1].name } },
                [
                  _c("div", { staticClass: "box-synopsis-right" }, [
                    _c("p", { staticClass: "subtitle" }, [
                      _vm._v(_vm._s(_vm.nav[item - 1].name)),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.nav[item - 1].first))]),
                    _c("p", [_vm._v(_vm._s(_vm.nav[item - 1].second))]),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "box-title" }, [_vm._v("订单流程")]),
      _c(
        "div",
        { staticClass: "select" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: _vm.is ? "primary" : "" },
              on: {
                click: function ($event) {
                  _vm.is = !_vm.is
                },
              },
            },
            [_vm._v("先货后款")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: _vm.is ? "" : "primary" },
              on: {
                click: function ($event) {
                  _vm.is = !_vm.is
                },
              },
            },
            [_vm._v("先款后货")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "process" },
        _vm._l(_vm.image, function (item, index) {
          return _c(
            "div",
            {
              key: _vm.is ? index + "a" : index + "b",
              staticClass: "process-img",
            },
            [_c("img", { attrs: { src: item, alt: "" } })]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }